import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs-src/src/layouts/MDXPageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`path: '/guidelines/color/'
title: 'Color'`}</p>


    <p>{`Contrast ratio between text and background colors must be at least 4.5:1`}</p>
    <h3>{`Color Contrast Tools:`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://color.a11y.com/?wc3"
        }}>{`https://color.a11y.com/?wc3`}</a></li>
      <li parentName="ul">{`For Sketch: Stark Plugin (`}<a parentName="li" {...{
          "href": "https://www.getstark.co/"
        }}>{`https://www.getstark.co/`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      